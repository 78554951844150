import { Layout, Menu, theme } from "antd";
import React, { useState, useEffect } from "react";
import AppRoutes from "../../AppRoutes";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "./../../assets/img/logo.svg";
import Navbar from "./Navbar";
import { checkPermission } from "../auth/ProtectedRoutes";

const { Content, Sider } = Layout;
const LayoutComponent = () => {
    let location = useLocation();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const itemsMenu = [
        {
            label: "Dashboard",
            type: "divider"
        },
        {
            key: "/",
            label: <NavLink to="/"> Dashboard</NavLink>,
        },
        {
            key: "/statistic-categories",
            className: !checkPermission("list-statistic-categories") && "d-none",
            label: (
                <NavLink to="/statistic-categories">
                    Statistic Categories
                </NavLink>
            ),
        },
        {
            key: "/statistics",
            className: !checkPermission("list-statistics") && "d-none",
            label: (
                <NavLink to="/statistics">
                    Statistics
                </NavLink>
            ),
        },
        {
            key: "/statistic-values",
            className: !checkPermission("list-statistic-values") && "d-none",
            label: (
                <NavLink to="/statistic-values">
                    Statistic Values
                </NavLink>
            ),
        },
        {
            key: "/unit-types",
            className: !checkPermission("list-unit-types") && "d-none",
            label: (
                <NavLink to="/unit-types">
                    Unit Types
                </NavLink>
            ),
        },
        {
            key: "/packages",
            className: !checkPermission("list-packages") && "d-none",
            label: (
                <NavLink to="/packages">
                    Packages
                </NavLink>
            ),
        },
        {
            key: "/subscription-histories",
            className: !checkPermission("list-subscribers") && "d-none",
            label: (
                <NavLink to="/subscription-histories">
                    Subscription Histories
                </NavLink>
            ),
        },
        {
            label: "Map",
            type: "divider"
        },
        {
            key: "/cities",
            className: !checkPermission("list-cities") && "d-none",
            label: (
                <NavLink to="/cities">
                    Cities
                </NavLink>
            ),
        },
        {
            key: "/neighborhoods",
            className: !checkPermission("list-neighborhoods") && "d-none",
            label: (
                <NavLink to="/neighborhoods">
                    Neighborhoods
                </NavLink>
            ),
        },
        {
            key: "/landmarks",
            className: !checkPermission("list-landmarks") && "d-none",
            label: (
                <NavLink to="/landmarks">
                    Landmarks
                </NavLink>
            ),
        },
        {
            label: "requests",
            type: "divider"
        },
        {
            key: "/invest-requests",
            className: !checkPermission("list-invest-requests") && "d-none",
            label: (
                <NavLink to="/invest-requests">
                    Invest Requests
                </NavLink>
            ),
        },
        {
            key: "/feedbacks",
            className: !checkPermission("list-feedbacks") && "d-none",
            label: (
                <NavLink to="/feedbacks">
                    Feedbacks
                </NavLink>
            ),
        },    
        {
            key: "/contact-messages",
            className: !checkPermission("list-contact-messages") && "d-none",
            label: (
                <NavLink to="/contact-messages">
                    Contact Messages
                </NavLink>
            ),
        },
        {
            label: "settings",
            type: "divider"
        },
        {
            key: "/users",
            className: !checkPermission("list-users") && "d-none",
            label: (
                <NavLink to="/users">
                    Users
                </NavLink>
            ),
        },
        {
            key: "/interests",
            className: !checkPermission("list-interests") && "d-none",
            label: (
                <NavLink to="/interests">
                    Interests
                </NavLink>
            ),
        },
        {
            key: "/companies",
            className: !checkPermission("list-companies") && "d-none",
            label: (
                <NavLink to="/companies">
                    Companies
                </NavLink>
            ),
        },
        {
            key: "/menus",
            className: !checkPermission("list-menus") && "d-none",
            label: (
                <NavLink to="/menus">
                    Menus
                </NavLink>
            ),
        },
        {
            key: "/pages",
            className: !checkPermission("list-page-translations") && "d-none",
            label: (
                <NavLink to="/pages">
                    Page translations
                </NavLink>
            ),
        },
        {
            key: "/messages-translations",
            className: !checkPermission("list-messages-translations") && "d-none",
            label: (
                <NavLink to="/messages-translations">
                    Messages translations
                </NavLink>
            ),
        },
        {
            key: "/invest-questions",
            className: !checkPermission("list-invest-question") && "d-none",
            label: (
                <NavLink to="/invest-questions">
                    Invest Questions
                </NavLink>
            ),
        },
        {
            key: "/estimation-questions",
            className: !checkPermission("list-estimation-question") && "d-none",
            label: (
                <NavLink to="/estimation-questions">
                    Estimation Questions
                </NavLink>
            ),
        },
        {
            key: "/estimates",
            className: !checkPermission("list-estimates") && "d-none",
            label: (
                <NavLink to="/estimates">
                    Estimates
                </NavLink>
            ),
        },
        {
            key: "/estimation-multipliers",
            className: !checkPermission("list-estimation-multiplier") && "d-none",
            label: (
                <NavLink to="/estimation-multipliers">
                    Estimation Multipliers
                </NavLink>
            ),
        },
        {
            key: "/Ratings",
            className: !checkPermission("list-ratings") && "d-none",
            label: (
                <NavLink to="/ratings">
                    Ratings
                </NavLink>
            ),
        },
        {
            key: "/settings",
            className: !checkPermission("list-settings") && "d-none",
            label: (
                <NavLink to="/settings">
                    Settings
                </NavLink>
            ),
        },
        {
            label: "Control dashboard",
            type: "divider"
        },
        {
            key: "/admins",
            className: !checkPermission("list-admins") && "d-none",
            label: (
                <NavLink to="/admins">
                    Admins
                </NavLink>
            ),
        },
        {
            key: "/roles",
            className: !checkPermission("list-roles") && "d-none",
            label: (
                <NavLink to="/roles">
                    Roles
                </NavLink>
            ),
        },    
    ];
    const [layout, setLayout] = useState(true);

    useEffect(() => {
        if (
            location.pathname === "/login" ||
            location.pathname === '/forget-password' || 
            location.pathname === '/otp' || 
            location.pathname === '/reset-password'
        ) {
            setLayout(false);
        } else {
            localStorage.setItem('lastPath', location.pathname)
        }

        const collection = document.querySelector(".ant-menu-item .active");
        if (collection) {
            collection.parentNode.parentNode.classList.add("item-selected");
        }
    }, [location.pathname]);
    return (
        <>
            {layout ? (
                <Layout>
                    <Sider
                        theme="light"
                        breakpoint="lg"
                        collapsedWidth="0"
                    >
                        <div className="demo-logo-vertical">
                            <Link className="text-center h-100" to="/">
                                <Logo />
                            </Link>
                        </div>
                        <Menu
                            theme="light"
                            mode="inline"
                            defaultSelectedKeys={location.pathname}
                            items={itemsMenu}
                        />
                    </Sider>
                    <Layout>
                        <Navbar />
                        <Content
                            style={{
                                margin: "24px 16px 0",
                            }}
                        >
                            <div
                                className="px-3"
                                style={{
                                    padding: "0 24",
                                    minHeight: 360,
                                    // background: colorBgContainer,
                                }}
                            >
                                <AppRoutes />
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            ) : (
                <AppRoutes />
            )}
        </>
    );
};
export default LayoutComponent;
