import React, { useEffect, useState } from "react";
import {
    Typography,
    Button,
    Spin,
    Form,
    Descriptions,
    Divider,
    notification,
    Input,
    Select,
} from "antd";
import { get, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const EditEstimate = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [userData, setUserData] = useState({});
    const [setSpecifications] = useState({});
    // const columns = [

    //     {
    //         title: " Question",
    //         render: ({ question }) => (
    //             <>
    //                 {question.name_en ?? "question"}
    //             </>
    //         )
    //     },
    //     {
    //         title: " Answer",
    //         render: ({ answer }) => (
    //             <>
    //                 {answer.name_en ?? "answer"}
    //             </>
    //         )
    //     },
    // ];

    useEffect(() => {
        get(`estimates/${id}`).then((res) => {
            setInputsValue({
                'comment': res.data.comment,
                'user_id': res.data.user.id,
                'rate_stars': res.data.rate_stars,
                'request_specifications': res.data.request_specifications,
            });

            setUserData(res.data.user)
            setSpecifications(res.data.request_specifications)
            setFromLoading(true);
            console.log(res.data.request_specifications);
        });
    }, []);


    const [dataRequestSpecifications, setDataRequestSpecifications] = useState([]);
    const [optionRequestSpecifications, setOptionRequestSpecifications] = useState([]);

    useEffect(() => {
        setOptionRequestSpecifications(
            dataRequestSpecifications.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataRequestSpecifications]);

    useEffect(() => {
        get("get-all-estimate-questions").then((res) => {
            setDataRequestSpecifications(res.data);
        });
    }, []);

    const [loading, setLoading] = useState(false);
    const onFinish = (values) => {
        setLoading(true);

        put(`estimates/${id}`, values)
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading(false);
                navigate("/estimates");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/estimates">
                    <ArrowLeftOutlined />
                    Edit Invest Request
                </Link>
            </Title>

            {fromLoading ?

                <>
                    <Divider />
                    <Descriptions title="User Info" bordered colon className="bg-white p-2">
                        <Descriptions.Item label="UserName">
                            {userData.name ?? ""}
                        </Descriptions.Item>
                        <Descriptions.Item label="Telephone">
                            {userData.phone ?? ""}
                        </Descriptions.Item>
                    </Descriptions>
                    {/* <Divider />

                    <Descriptions title="specifications" bordered colon className="bg-white p-2">
                        <div>
                            <div className="w-100">
                                <Table
                                    locale={{ emptyText: "There are no data" }}
                                    loading={loading}
                                    columns={columns}
                                    dataSource={specifications}
                                    size="small"
                                    responsive
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </Descriptions> */}

                    <Divider />
                    <Form
                        className="wrapper-content"
                        onFinish={onFinish}
                        labelCol={{
                            span: 7,
                        }}
                        wrapperCol={{
                            span: 15,
                        }}
                        initialValues={{
                            comment: inputsValue.comment,
                            user_id: inputsValue.user_id,
                            rate_stars: inputsValue.rate_stars,
                            request_specifications: inputsValue.request_specifications,
                        }}
                        autoComplete="off"
                    >
                        <div className="row">
                            <div className="col-lg-12 d-none">
                                <Form.Item
                                    label=""
                                    name="user_id">
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="col-lg-12">
                                <Form.Item
                                    label="Rate Stars"
                                    name="rate_stars">
                                    <Input placeholder="Type..." />
                                </Form.Item>
                            </div>
                            {/* Item */}
                            <div className="col-lg-12">
                                <Form.Item
                                    label="Request Specifications"
                                    name="request_specifications"
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        className="w-100"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        placeholder="Select"
                                        options={optionRequestSpecifications}
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-lg-12">
                                <Form.Item
                                    label="Comment"
                                    name="comment">
                                    <ReactQuill theme="snow" />
                                </Form.Item>
                            </div>

                            <div className="col-lg-4 offset-lg-4">
                                <Form.Item
                                    wrapperCol={{
                                        offset: 1,
                                        span: 16,
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        size="large"
                                        block
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {loading ? "Updating..." : "Update"}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </>

                : (
                    <div className="text-center">
                        <Spin size="large" />
                    </div>
                )}
        </div>
    );
};

export default EditEstimate;
