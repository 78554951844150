import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Select, Form, notification } from "antd";
import { get, post } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddRole = () => {
    const navigate = useNavigate();

    // const [inputsValue, setInputsValue] = useState({});
    const [dataPermissions, setDataPermissions] = useState([]);
    const [optionPermissions, setOptionPermissions] = useState([]);

    useEffect(() => {
        setOptionPermissions(
            dataPermissions.map((d) => ({
                value: d.key,
                label: d.display_name,
            }))
        );
    }, [dataPermissions]);

    useEffect(() => {
        get("get-all-permissions").then((res) => {
            setDataPermissions(res.data);
        });
    }, []);

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        

        post("roles", values)
            .then((res) => {
                notification.success({message:"Added successfully"});
                setLoading(false);
                navigate("/roles");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/roles">
                    <ArrowLeftOutlined />
                </Link>
                Add Role
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    <div className="col-lg-12">
                        <Form.Item
                            label="Name "
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>

                    <div className="col-lg-12">
                        <Form.Item
                            label="Permissions"
                            name="permissions"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={optionPermissions}
                            />
                        </Form.Item>
                    </div>

                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "Saving..." : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddRole;
