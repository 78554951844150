import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Select, Form, Switch, Card, notification } from "antd";
import { get, post } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddEstimationQuestion = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [typeQuesion, setTypeQuesion] = useState('choice');
    const [form] = Form.useForm();
    const [inputsValue, setInputsValue] = useState({
        is_active: true,
        is_applied: true,
        accept_zero: true,
    });


    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };

    const [dataApplicablePropertyTypes, setDataApplicablePropertyTypes] = useState([]);
    const [optionApplicablePropertyTypes, setOptionApplicablePropertyTypes] = useState([]);

    useEffect(() => {
        setOptionApplicablePropertyTypes(
            dataApplicablePropertyTypes.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataApplicablePropertyTypes]);

    useEffect(() => {
        get("get-all-unit-types").then((res) => {
            setDataApplicablePropertyTypes(res.data);
        });
    }, []);


    const onFinish = (values) => {
        setLoading('loading');
        if (values.answers) {
            
            values.answers.map((item, index) => {
                if (item.is_active === undefined) {
                    item.is_active = false
                }
            })
        } else{
            values.answers = []
        }


        post("estimation-questions",
            {
                ...values,
                ...inputsValue,
            })
            .then((res) => {
                notification.success({ message: "Added successfully" });
                setLoading('');
                navigate("/estimation-questions");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            })
    };


    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/estimation-questions">
                    <ArrowLeftOutlined />
                </Link>
                Add Estimation Question
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                form={form}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    answers: [{}],
                    'type': 'choice'
                }}
            >
                <div className="row">
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name Ar "
                            name="name_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name En "
                            name="name_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order"
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input type="number" placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Applicable Property"
                            name="applicable_property_types"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={optionApplicablePropertyTypes}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Active" name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Applied" name="is_applied">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_applied")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Type"
                            name="type"
                        >
                            <Select
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={[
                                    {
                                        label: 'Choice',
                                        value: 'choice'
                                    },
                                    {
                                        label: 'Number',
                                        value: 'number'
                                    },
                                ]}
                                onChange={v => setTypeQuesion(v)}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6" >
                        <Form.Item label="Allow Skipping" name="accept_zero">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "accept_zero")}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Unit Area Question" name="is_units_area">
                            <Switch defaultChecked={false} onChange={(status) => handleSwitch(status, "is_units_area")}
                            />
                        </Form.Item>
                    </div>

                    {
                        typeQuesion === 'choice' &&

                        <div className="col-lg-12">
                            <Card
                                size="small"
                            >
                                {/* Nest Form.List */}
                                <Form.Item label="answers?">
                                    <Form.List name='answers'>
                                        {(subFields, subOpt) => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    rowGap: 16,
                                                }}
                                            >
                                                {subFields.map((subField) => (
                                                    <div className="row" key={subField.key}>
                                                        <div className="col-lg-6">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 1,
                                                                    span: 16,
                                                                }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "This field is required!",
                                                                    }
                                                                ]}
                                                                label='Name Ar' name={[subField.name, 'name_ar']}>
                                                                <Input placeholder="Type..." />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 1,
                                                                    span: 18,
                                                                }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "This field is required!",
                                                                    }
                                                                ]}
                                                                label='Name En' name={[subField.name, 'name_en']}>
                                                                <Input placeholder="Type..." />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 1,
                                                                    span: 18,
                                                                }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "This field is required!",
                                                                    }
                                                                ]}
                                                                label='Order' name={[subField.name, 'order']}>
                                                                <Input className="ms-3" type="number" placeholder="Type..." />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 1,
                                                                    span: 18,
                                                                }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "This field is required!",
                                                                    }
                                                                ]}
                                                                label='Weight' name={[subField.name, 'weight']}>
                                                                <Input className="ms-3" type="number" placeholder="Type..." />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-5">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 3,
                                                                    span: 16,
                                                                }}
                                                                label='Active' name={[subField.name, 'is_active']}>
                                                                <Switch
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-1 text-end">
                                                            <CloseOutlined
                                                                className="border"
                                                                onClick={() => {
                                                                    subOpt.remove(subField.name);
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                ))}
                                                <Button type="dashed" onClick={() => subOpt.add()} block>
                                                    + Add Other Answer
                                                </Button>
                                            </div>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            </Card>
                        </div>
                    }
                    {/* Item */}

                    <div className="col-lg-4 offset-lg-4 mt-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading === 'loading' ? true : loading === 'uploading' ? true : false}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading === 'loading' ? "Saving..." : loading === 'uploading' ? 'Uploading' : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddEstimationQuestion;
