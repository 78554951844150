import React, { useState } from 'react'
import { Input, Typography, Button, Form, notification } from 'antd';
import { post } from '../../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title } = Typography;

const ChangePassword = () => {
    const navigate = useNavigate();

    // const [inputsValue, setinputsValue] = useState(
    //     {
    //         "old_password": "",
    //         "new_password": "",
    //         "new_password_confirmation": ""
    //     }
    // );



    const [loading, setLoading] = useState(false);

    // const newPassword = (e) => {
    //     setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })
    // }
    // const passwordConfirmation = (e) => {

    //     if (e.target.value != inputsValue.new_password) {
    //         alert('1')
    //     }
    // }


    const onFinish = (values) => {
        setLoading(true);


        post('change-password', values).then(res => {
            notification.success({message:'Changed successfully'});
            setLoading(false);
            navigate('/')
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        })

    };



    return (
        <div>
            <Title level={2} className='text-main mb-2'>
                <Link className='text-dark me-3' to='/'>
                    <ArrowLeftOutlined />
                </Link>
                Change Password
            </Title>
            <Form
                className='wrapper-content'
                onFinish={onFinish}
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 15,
                }}

                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className='row'>

                    <div className='col-lg-12'>

                        <Form.Item
                            label='Old Password '
                            name='old_password'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Input.Password placeholder='Type...' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>

                        <Form.Item
                            label='New Password'
                            name='new_password'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Input.Password placeholder='Type...' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>

                        <Form.Item
                            label='New Password Confirmation'
                            name='new_password_confirmation'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder='Type...' />
                        </Form.Item>
                    </div>


                    <div className='col-lg-4 offset-lg-4'>

                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size='large' block type="primary" htmlType="submit">
                                {loading ? 'Saving...' : 'Sava'}
                            </Button>
                        </Form.Item>
                    </div>
                </div>



            </Form>
        </div>
    )
}

export default ChangePassword