import React, { useEffect, useState } from "react";
import { Input, Typography, Button, Form, Switch, Upload, Select, notification } from "antd";
import { get, post, postFromData } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const AddCompany = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dataTypes, setDataTypes] = useState([]);
    const [optionTypes, setOptionTypes] = useState([]);

    useEffect(() => {
        setOptionTypes(
            dataTypes.map((d) => ({
                value: d.value,
                label: d.name,
            }))
        );
    }, [dataTypes]);

    useEffect(() => {
        get("get-all-company-types").then((res) => {
            setDataTypes(res.data);
        });
    }, []);


    const [inputsValue, setInputsValue] = useState({
        is_active: true,
    });

    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };

    const handleBeforeUpload = (e) => {
        setLoading('uploading')
        postFromData('/upload-image', { 'image': e, 'store_path': 'companies' }).then(res => {
            setInputsValue({ ...inputsValue, logo: res.data })
            setLoading('');
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        });
    }

    const onFinish = (values) => {
        setLoading('loading');
        

        post("companies", {
            ...values,
            ...inputsValue,
        })
            .then((res) => {
                notification.success({message:"Added successfully"});
                setLoading(false);
                navigate("/companies");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/companies">
                    <ArrowLeftOutlined />
                </Link>
                Add Company
            </Title>
            <Form
                className="wrapper-content"
                // layout='vertical'
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Logo'
                            name='logo'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={handleBeforeUpload}
                                // action={`${baseUrl}upload-image`}
                                // headers={{ "Authorization": `Bearer ${token}` }}
                                listType="picture"
                                maxCount={1}
                            // onChange={handleUpload}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Order "
                            name="order"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Select
                                className="w-100"
                                filterOption={(input, option) =>
                                    (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                placeholder="Select"
                                options={optionTypes}
                            />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Active " name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading === 'loading' ? true : loading === 'uploading' ? true : false}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading === 'loading' ? "Saving..." : loading === 'uploading' ? 'Uploading' : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddCompany;
