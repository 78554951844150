import React, { useEffect, useState } from "react";
import {
    Input,
    Typography,
    Button,
    Spin,
    Form,
    Select,
    Switch,
    notification,
    Upload,
} from "antd";
import { get, postFromData, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";

const { Title } = Typography;

const EditStatistic = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [fileListAr, setFileListAr] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: '',
        },
    ]);
    const [fileListEn, setFileListEn] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: '',
        },
    ]);

    useEffect(() => {
        get(`statistics/${id}`).then((res) => {
            setInputsValue(res.data.data);
            setFileListAr(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.data.video_thumbnail_ar
                    },
                ]
            )
            setFileListEn(
                [
                    {
                        uid: '-1',
                        name: 'image.png',
                        status: 'done',
                        url: res.data.data.video_thumbnail_en
                    },
                ]
            )
            setFromLoading(true);
        });
    }, [id]);

    const [loading, setLoading] = useState(false);

    // const handlePicker = (color, name) => {
    //     setInputsValue({ ...inputsValue, [name]: color.toHexString() });
    // };


    const [dataPackages, setDataPackages] = useState([]);
    const [optionPackages, setOptionPackages] = useState([]);

    useEffect(() => {
        setOptionPackages(
            dataPackages.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataPackages]);

    useEffect(() => {
        get("get-all-packages").then((res) => {
            setDataPackages(res.data);
        });
    }, []);

    const [dataCategories, setDataCategories] = useState([]);
    const [optionCategories, setOptionCategories] = useState([]);

    useEffect(() => {
        setOptionCategories(
            dataCategories.map((d) => ({
                value: d.id,
                label: d.name_en,
            }))
        );
    }, [dataCategories]);

    useEffect(() => {
        get("get-all-statistic-categories").then((res) => {
            setDataCategories(res.data);
        });
    }, []);

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
    }
    const handleBeforeUploadAr = (e) => {
        setLoading('uploading')
        postFromData('/upload-image', { 'image': e, 'store_path': 'statistics', 'old_path': inputsValue.video_thumbnail_ar_path }).then(res => {
            setInputsValue({ ...inputsValue, video_thumbnail_ar_path: res.data })
            setLoading('');
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        })
    }
    const handleBeforeUploadEn = (e) => {
        setLoading('uploading')
        postFromData('/upload-image', { 'image': e, 'store_path': 'statistics', 'old_path': inputsValue.video_thumbnail_en_path }).then(res => {
            setInputsValue({ ...inputsValue, video_thumbnail_en_path: res.data })
            setLoading('');
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        })
    }


    const onFinish = (values) => {
        setLoading(true);
        put(`statistics/${id}`, {
            ...values,
            min_value_map_color: inputsValue.min_value_map_color,
            max_value_map_color: inputsValue.max_value_map_color,
            video_thumbnail_ar: inputsValue.video_thumbnail_ar_path,
            video_thumbnail_en: inputsValue.video_thumbnail_en_path,
        })
            .then((res) => {
                notification.success({message:"Modified successfully"});
                setLoading(false);
                navigate("/statistics");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };


    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/statistics">
                    <ArrowLeftOutlined />
                </Link>
                Edit Statistic
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        name_ar: inputsValue.name_ar,
                        name_en: inputsValue.name_en,
                        video_tip_ar: inputsValue.video_tip_ar,
                        video_tip_en: inputsValue.video_tip_en,
                        video_url_ar: inputsValue.video_url_ar,
                        video_url_en: inputsValue.video_url_en,
                        more_info_ar: inputsValue.more_info_ar,
                        more_info_en: inputsValue.more_info_en,
                        more_info_url_ar: inputsValue.more_info_url_ar,
                        more_info_url_en: inputsValue.more_info_url_en,
                        // min_value_map_color: inputsValue.min_value_map_color,
                        // max_value_map_color: inputsValue.max_value_map_color,
                        order: inputsValue.order,
                        forecastable: inputsValue.forecastable,
                        compoudable: inputsValue.compoudable,
                        is_active: inputsValue.is_active,
                        is_soon: inputsValue.is_soon,
                        is_percentage: inputsValue.is_percentage,
                        decimal_places: inputsValue.decimal_places,
                        package_ids: inputsValue.packages_ids,
                        statistic_category_id: inputsValue.statistic_category_id,
                    }}
                    autoComplete="off"
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name Ar"
                                name="name_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name En "
                                name="name_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Video tip ar "
                                name="video_tip_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Video tip en "
                                name="video_tip_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Video url ar "
                                name="video_url_ar"
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Video url en "
                                name="video_url_en"
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className='col-lg-6'>
                            <Form.Item
                                label='Image'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUploadAr}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={fileListAr}
                                    onPreview={onPreview}

                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className='col-lg-6'>
                            <Form.Item
                                label='Image'
                            >
                                <Upload
                                    beforeUpload={handleBeforeUploadEn}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={fileListEn}
                                    onPreview={onPreview}

                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="More info url ar "
                                name="more_info_url_ar"
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="More info url en "
                                name="more_info_url_en"
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="More info ar "
                                name="more_info_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <ReactQuill theme="snow" />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="More info en "
                                name="more_info_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <ReactQuill theme="snow" />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        {/* <div className="col-lg-6">
                            <Form.Item
                                label="Min value map color "
                                name="min_value_map_color"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <ColorPicker
                                    defaultValue="#0277bd"
                                    onChange={(color) =>
                                        handlePicker(color, "min_value_map_color")
                                    }
                                    size="large"
                                />
                            </Form.Item>
                        </div> */}
                        {/* Item */}
                        {/* <div className="col-lg-6">
                            <Form.Item
                                label="Max value map color "
                                name="max_value_map_color"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <ColorPicker
                                    defaultValue="#0277bd"
                                    onChange={(color) =>
                                        handlePicker(color, "max_value_map_color")
                                    }
                                    size="large"
                                />
                            </Form.Item>
                        </div> */}
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Category"
                                name="statistic_category_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionCategories}
                                />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order "
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Forecastable" name="forecastable">
                                <Switch defaultChecked={inputsValue.forecastable} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Compoudable " name="compoudable">
                                <Switch defaultChecked={inputsValue.compoudable} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Soon " name="is_soon">
                                <Switch defaultChecked={inputsValue.is_soon} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch defaultChecked={inputsValue.is_active} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Is Percentage " name="is_percentage">
                                <Switch defaultChecked={inputsValue.is_percentage} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Decimal Places "
                                name="decimal_places"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    }
                                ]}
                            >
                                <Input type="number"
                                    min={0}
                                    max={2}
                                    placeholder="Type..." />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="Subscription"
                                name="package_ids"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionPackages}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Updating..." : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditStatistic;
