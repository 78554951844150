import React, { useEffect, useState } from "react";
import {
    Input,
    Typography,
    Button,
    Spin,
    Form,
    Upload,
    Switch,
    Card,
    notification,
    message,
} from "antd";
import { get, postFromData, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined, CloseOutlined, UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditInvestQuestion = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [form] = Form.useForm();

    const [fromLoading, setFromLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});
    const [fileList, setFileList] = useState([]);


    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
    }


    useEffect(() => {
        get(`invest-questions/${id}`).then((res) => {
            setInputsValue(res.data.data);
            setFromLoading(true);
            setFileList(
                [
                    {
                        uid: '-1',
                        name: res.data.data.icon_path,
                        status: 'done',
                        url: res.data.data.icon,
                    },
                ]
            )
        });
    }, [id]);


    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };


    const handleBeforeUpload = (e) => {
        setLoading('uploading');
        postFromData('/upload-image', { 'image': e, 'store_path': 'invest-questions', 'old_path': inputsValue.icon_path })
        .then(res => {
            setInputsValue({ ...inputsValue, icon_path: process.env.REACT_APP_BASE_IMAGE_URL + res.data })
            setLoading('');
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        })
    }




    const onFinish = (values) => {
        setLoading('loading');
        values.answers.map((item, index) => {
            if (item !== undefined) {
                if (item.length !== 0) {

                    if (item.is_active === undefined) {
                        item.is_active = false
                    }
                }
                else {
                    message.error(`please add answer list to answer 0: ${index + 1}`)
                }

            } else {
                message.error(`please add answer list to answer ${index + 1}`)
            }
        })


        put(`invest-questions/${id}`, { ...values, 'icon': inputsValue.icon_path  })
            .then((res) => {
                notification.success({message:"Modified successfully"});
                setLoading('');
                navigate("/invest-questions");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };



    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/invest-questions">
                    <ArrowLeftOutlined />
                    Edit Invest Question
                </Link>
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    form={form}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        question_ar: inputsValue.question_ar,
                        question_en: inputsValue.question_en,
                        order: inputsValue.order,
                        is_active: inputsValue.is_active,
                        is_soon: inputsValue.is_soon,
                        answers: inputsValue.answers,
                        icon: inputsValue.icon_path
                    }}
                >
                    <div className="row">


                        <div className="col-lg-6">
                            <Form.Item
                                label="Question Ar "
                                name="question_ar"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="Question En "
                                name="question_en"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        <div className="col-lg-6">
                            <Form.Item
                                label="Order"
                                name="order"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="Type..." />
                            </Form.Item>
                        </div>

                        <div className="col-lg-6">
                            <Form.Item label="Active" name="is_active">
                                <Switch
                                    defaultChecked={inputsValue.is_active}
                                    onChange={(status) => handleSwitch(status, "is_active")}
                                />
                            </Form.Item>
                        </div>

                        {/* Item */}
                        <div className='col-lg-6'>
                            <Form.Item
                                label='Icon'
                                name='icon'
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Upload
                                    beforeUpload={handleBeforeUpload}
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={fileList}
                                    onPreview={onPreview}

                                >
                                    <Button icon={<UploadOutlined />}> Click to upload an image </Button>
                                </Upload>
                            </Form.Item>
                        </div>

                        <div className="col-lg-12">
                            <Card
                                size="small"
                            >
                                {/* Nest Form.List */}
                                <Form.Item label="List">
                                    <Form.List name='answers'>
                                        {(subFields, subOpt) => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    rowGap: 16,
                                                }}
                                            >
                                                {subFields.map((subField) => (
                                                    <div className="row" key={subField.key}>
                                                        <div className="col-lg-12">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 1,
                                                                    span: 18,
                                                                }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "This field is required!",
                                                                    }
                                                                ]}
                                                                label='Answer Ar' name={[subField.name, 'answer_ar']}>
                                                                <Input placeholder="Type..." />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 1,
                                                                    span: 18,
                                                                }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "This field is required!",
                                                                    }
                                                                ]}
                                                                label='Answer En' name={[subField.name, 'answer_en']}>
                                                                <Input placeholder="Type..." />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 2,
                                                                    span: 16,
                                                                }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "This field is required!",
                                                                    }
                                                                ]}
                                                                label='Order' name={[subField.name, 'order']}>
                                                                <Input className="ms-3" type="number" placeholder="Type..." />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 3,
                                                                    span: 16,
                                                                }}
                                                                label='Soon' name={[subField.name, 'is_soon']}>
                                                                <Switch
                                                                defaultChecked={inputsValue.answers[subField.name] ? inputsValue.answers[subField.name].is_soon : false}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <Form.Item
                                                                wrapperCol={{
                                                                    offset: 3,
                                                                    span: 16,
                                                                }}
                                                                label='Active' name={[subField.name, 'is_active']}>
                                                                <Switch
                                                                defaultChecked={inputsValue.answers[subField.name] ? inputsValue.answers[subField.name].is_active : false}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-lg-1 text-end">
                                                            <CloseOutlined
                                                                className="border"
                                                                onClick={() => {
                                                                    subOpt.remove(subField.name);
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                ))}
                                                <Button type="dashed" onClick={() => subOpt.add()} block>
                                                    + Add Sub List
                                                </Button>
                                            </div>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            </Card>

                            {/* <Form.Item noStyle shouldUpdate>
                                {() => (
                                    <Typography>
                                        <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                                    </Typography>
                                )}
                            </Form.Item> */}
                        </div>


                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading === 'loading' ? true : loading === 'uploading' ? true : false}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading === 'loading' ? "Updating..." : loading === 'uploading' ? 'Uploading' : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditInvestQuestion;
