import React, { useState } from "react";
import {
    Typography,
    Button,
    Form,
    Upload,
    notification,
} from "antd";
import { postFromData } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";

import './styles.css';

const { Title } = Typography;

const AddNeighborhood = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    const [inputsValue, setInputsValue] = useState({
    });

    const handleBeforeUpload = (e) => {
        setInputsValue({ ...inputsValue, excel: e })
    }

    const onFinish = (values) => {
        setLoading(true);
        
        postFromData("neighborhoods", inputsValue)
            .then((res) => {
                notification.success({message:"Added successfully"});
                setLoading(false);
                setTimeout(function () {
                    
                    navigate("/neighborhoods");
                }, 2000);
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/neighborhoods">
                    <ArrowLeftOutlined />
                </Link>
                Add Neighborhood
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                autoComplete="off"
                initialValues={{
                    remember: true,
                }}
            >
                <div className="row">
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label='Excel'
                            name='excel'
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required!',
                                },
                            ]}
                        >
                            <Upload
                                action={false}
                                beforeUpload={handleBeforeUpload}
                                listType="picture"
                                maxCount={1}
                            // onChange={handleUpload}
                            >
                                <Button icon={<UploadOutlined />}> Click to upload an excel </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-4 offset-lg-4">
                    <Form.Item
                        wrapperCol={{
                            offset: 1,
                            span: 16,
                        }}
                    >
                        <Button
                            loading={loading}
                            size="large"
                            block
                            type="primary"
                            htmlType="submit"
                        >
                            {loading ? "Saving..." : "Save"}
                        </Button>
                    </Form.Item>
                </div>
            </div>
            </Form>

        </div>
    );
};

export default AddNeighborhood;
