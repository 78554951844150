import React from "react";
import { ProtectedRoutesAdmin } from "./components/auth/ProtectedRoutes";
import { Routes, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import Roles from "./components/roles/Roles";
import AddRole from "./components/roles/AddRole";
import EditRole from "./components/roles/EditRole";
import Statistics from "./components/statistics/Statistics";
import AddStatistic from "./components/statistics/AddStatistic";
import EditStatistic from "./components/statistics/EditStatistic";
import Admins from "./components/admins/Admins";
import AddAdmin from "./components/admins/AddAdmin";
import EditAdmin from "./components/admins/EditAdmin";
import UnitTypes from "./components/unit_types/UnitTypes";
import AddUnitType from "./components/unit_types/AddUnitType";
import EditUnitType from "./components/unit_types/EditUnitType";
import Settings from "./components/settings/Settings";
import EditPage from "./components/pages/EditPage";
import Pages from "./components/pages/Pages";
import Menus from "./components/menus/Menus";
import AddMenu from "./components/menus/AddMenu";
import InvestmentAnswerCategories from "./components/invest_answer_categories/InvestAnswerCategories";
import AddInvestAnswerCategory from "./components/invest_answer_categories/AddInvestAnswerCategory";
import EditInvestAnswerCategory from "./components/invest_answer_categories/EditInvestAnswerCategory";
import EditMenu from "./components/menus/EditMenu";
import InvestQuestions from "./components/invest_questions/InvestQuestions";
import AddInvestQuestion from "./components/invest_questions/AddInvestQuestion";
import EditInvestQuestion from "./components/invest_questions/EditInvestQuestion";
import Feedbacks from "./components/feedbacks/Feedbacks";
import EditFeedback from "./components/feedbacks/EditFeedback";
import ChangePassword from "./components/profile/ChangePassword";
import Companies from "./components/companies/Companies";
import AddCompany from "./components/companies/AddCompany";
import EditCompany from "./components/companies/EditCompany";
import InvestRequests from "./components/invest_requests/InvestRequests";
import EditInvestRequest from "./components/invest_requests/EditInvestRequest";
import ForgetPassword from "./components/auth/forget_password";
import Otp from "./components/auth/otp";
import ResetPassword from "./components/auth/reset_password";
import EditContactMessage from "./components/contact-messages/EditContactMessage";
import ContactMessages from "./components/contact-messages/ContactMessages";
import AddStatisticValues from "./components/statistic-values/AddStatisticValues";
import Users from "./components/users/Users";
import EditUser from "./components/users/EditUser";
import Packages from "./components/packages/Packages";
import StatisticValues from "./components/statistic-values/StatisticValues";
import AddPackage from "./components/packages/AddPackage";
import EditPackage from "./components/packages/EditPackage";
import SubscriptionHistories from "./components/subscritpion-histories/SubscriptionHistories";
import Cities from "./components/cities/Cities";
import AddCity from "./components/cities/AddCity";
import EditCity from "./components/cities/EditCity";
import Neighborhoods from "./components/neighborhoods/Neighborhoods";
import EditNeighborhood from "./components/neighborhoods/EditNeighborhood";
import AddNeighborhood from "./components/neighborhoods/AddNeighborhood";
import Interests from "./components/interests/Interests";
import EditStatisticValues from "./components/statistic-values/EditStatisticValues";
import MessagesTranslations from "./components/message-translations/MessagesTranslations";
import EditMessagesTranslation from "./components/message-translations/EditMessagesTranslation";
import StatisticCategories from "./components/statistic-categories/StatisticCategories";
import AddStatisticCategory from "./components/statistic-categories/AddStatisticCategory";
import EditStatisticCategory from "./components/statistic-categories/EditStatisticCategory";
import Ratings from "./components/ratings/Ratings";
import Landmarks from "./components/landmarks/Landmarks";
import AddLandmark from "./components/landmarks/AddLandmark";
import EditLandmark from "./components/landmarks/EditLandmark";
import EstimationQuestions from "./components/estimation_questions/EstimationQuestions";
import AddEstimationQuestion from "./components/estimation_questions/AddEstimationQuestion";
import EditEstimationQuestion from "./components/estimation_questions/EditEstimationQuestion";
import Estimates from "./components/estimates/Estimates";
import EditEstimate from "./components/estimates/EditEstimate";
import EstimationMultipliers from "./components/estimation_multipliers/EstimationMultipliers";
import AddEstimationMultiplier from "./components/estimation_multipliers/AddEstimationMultiplier";

const AppRoutes = () => {
    return (
        <div>
            <Routes>
                <Route element={<ProtectedRoutesAdmin />}>
                    <Route path="/roles">
                        <Route path="" element={<Roles />} />
                        <Route path="add" element={<AddRole />} />
                        <Route path="edit/:id" element={<EditRole />} />
                    </Route>
                    <Route path="/admins">
                        <Route path="" element={<Admins />} />
                        <Route path="add" element={<AddAdmin />} />
                        <Route path="edit/:id" element={<EditAdmin />} />
                    </Route>
                    <Route path="/users">
                        <Route path="" element={<Users />} />
                        <Route path="edit/:id" element={<EditUser />} />
                    </Route>
                    <Route path="/statistics">
                        <Route path="" element={<Statistics />} />
                        <Route path="add" element={<AddStatistic />} />
                        <Route path="edit/:id" element={<EditStatistic />} />
                    </Route>
                    <Route path="/unit-types">
                        <Route path="" element={<UnitTypes />} />
                        <Route path="add" element={<AddUnitType />} />
                        <Route path="edit/:id" element={<EditUnitType />} />
                    </Route>
                    <Route path="/pages">
                        <Route path="" element={<Pages />} />
                        <Route path="edit/:id" element={<EditPage />} />
                    </Route>
                    <Route path="/menus">
                        <Route path="" element={<Menus />} />
                        <Route path="add" element={<AddMenu />} />
                        <Route path="edit/:id" element={<EditMenu />} />
                    </Route>
                    <Route path="/invest-answer-categories">
                        <Route path="" element={<InvestmentAnswerCategories />} />
                        <Route path="add" element={<AddInvestAnswerCategory />} />
                        <Route path="edit/:id" element={<EditInvestAnswerCategory />} />
                    </Route>
                    <Route path="/invest-questions">
                        <Route path="" element={<InvestQuestions />} />
                        <Route path="add" element={<AddInvestQuestion />} />
                        <Route path="edit/:id" element={<EditInvestQuestion />} />
                    </Route>
                    <Route path="/statistic-values">
                        <Route path="" element={<StatisticValues />} />
                        <Route path="add" element={<AddStatisticValues />} />
                        <Route path="edit/:id" element={<EditStatisticValues />} />
                    </Route>
                    <Route path="/companies">
                        <Route path="" element={<Companies />} />
                        <Route path="add" element={<AddCompany />} />
                        <Route path="edit/:id" element={<EditCompany />} />
                    </Route>
                    <Route path="/packages">
                        <Route path="" element={<Packages />} />
                        <Route path="add" element={<AddPackage />} />
                        <Route path="edit/:id" element={<EditPackage />} />
                    </Route>
                    <Route path="/cities">
                        <Route path="" element={<Cities />} />
                        <Route path="add" element={<AddCity />} />
                        <Route path="edit/:id" element={<EditCity />} />
                    </Route>
                    <Route path="/neighborhoods">
                        <Route path="" element={<Neighborhoods />} />
                        <Route path="add" element={<AddNeighborhood />} />
                        <Route path="edit/:id" element={<EditNeighborhood />} />
                    </Route>
                    <Route path="/landmarks">
                        <Route path="" element={<Landmarks />} />
                        <Route path="add" element={<AddLandmark />} />
                        <Route path="edit/:id" element={<EditLandmark />} />
                    </Route>
                    <Route path="/invest-requests">
                        <Route path="" element={<InvestRequests />} />
                        <Route path="edit/:id" element={<EditInvestRequest />} />
                    </Route>
                    <Route path="/feedbacks">
                        <Route path="" element={<Feedbacks />} />
                        <Route path="edit/:id" element={<EditFeedback />} />
                    </Route>
                    <Route path="/contact-messages">
                        <Route path="" element={<ContactMessages />} />
                        <Route path="edit/:id" element={<EditContactMessage />} />
                    </Route>
                    <Route path="/messages-translations">
                        <Route path="" element={<MessagesTranslations />} />
                        <Route path="edit/:id" element={<EditMessagesTranslation />} />
                    </Route>

                    <Route path="/statistic-categories">
                        <Route path="" element={<StatisticCategories />} />
                        <Route path="add" element={<AddStatisticCategory />} />
                        <Route path="edit/:id" element={<EditStatisticCategory />} />
                    </Route>
                    
                    <Route path="/estimation-questions">
                        <Route path="" element={<EstimationQuestions />} />
                        <Route path="add" element={<AddEstimationQuestion />} />
                        <Route path="edit/:id" element={<EditEstimationQuestion />} />
                    </Route>
                    <Route path="/estimates">
                        <Route path="" element={<Estimates />} />
                        <Route path="edit/:id" element={<EditEstimate />} />
                    </Route>
                    <Route path="/estimation-multipliers">
                        <Route path="" element={<EstimationMultipliers />} />
                        <Route path="add" element={<AddEstimationMultiplier />} />
                    </Route>

                    <Route path="/settings" element={<Settings />} />
                    <Route path="/subscription-histories" element={<SubscriptionHistories />} />
                    <Route path="/interests" element={<Interests />} />
                    <Route path="/" element={<Statistics />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/ratings" element={<Ratings />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/otp" element={<Otp />} />
                <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
        </div>
    );
};

export default AppRoutes;
