import React, { useState } from "react";
import {
    Input,
    Typography,
    Button,
    Form,
    Switch,
    notification,
} from "antd";
import { post } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { GoogleMap, MarkerF, Autocomplete, useLoadScript } from "@react-google-maps/api";
import { getGeocode } from "use-places-autocomplete";
import { GoogleMapsAPI, DefaultLatLng, GoogleMapLibrary } from "../../api/client-config";

const { Title } = Typography;

const AddCity = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({
        is_active: true,
    });

    const handleSwitch = (status, name) => {
        setInputsValue({ ...inputsValue, [name]: status });
    };

    const [mapPosition, setMapPosition] = useState(DefaultLatLng);
    
    const [markerPosition, setMarkerPosition] = useState(DefaultLatLng);
    
    const [mapRef, setMapRef] = useState(null);
    
    const [acRef, setAcRef] = useState(null);

    const [currentAddress, setCurrentAddress] = useState("");

    const handleOnLoad = (map) => {
        setMapRef(map);
    };

    const handleOnLoadAC = (autocomplete) => {
        setAcRef(autocomplete);
    };

    const handleOnCenterChanged = () => {
        if (mapRef) {
            const centerMap = mapRef.getCenter();
            const centerLat = centerMap?.lat();
            const centerLng = centerMap?.lng();
            if (centerLat && centerLng) {
                setMarkerPosition({
                    lat: centerLat,
                    lng: centerLng
                });
            }
        }
    };

    const handleOnDragEnd = async () => {
        if (!mapRef) return;
        const centerMap = mapRef.getCenter();
        const res = await getGeocode({ centerMap });
        const streetAddress = res.find(({ types }) =>
            types.includes("street_address")
        );
        setCurrentAddress(
            streetAddress?.formatted_address ?? res[0].formatted_address
        );
    };

    const handleOnACSelected = () => {
        if (acRef) {
            const selectedPlace = acRef.getPlace();
            const selectedLat = selectedPlace.geometry?.location?.lat();
            const selectedLng = selectedPlace.geometry?.location?.lng();
            if (selectedLat && selectedLng) {
                setMapPosition({
                    lat: selectedLat,
                    lng: selectedLng
                });
            }
        }
    };

    const { isLoaded } = useLoadScript({
        id: "map",
        googleMapsApiKey: GoogleMapsAPI,
        libraries: GoogleMapLibrary
    });

    const onFinish = (values) => {
        post("cities", {
            ...values,
            ...inputsValue,
            'map_center':markerPosition,
            'default_zoom_level': mapRef.zoom
        }).then((res) => {
            notification.success({message:"Added successfully"});
            setLoading(false);
            navigate("/cities");
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
            setLoading(false);
        });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/cities">
                    <ArrowLeftOutlined />
                </Link>
                Add City
            </Title>
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className="row">
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name Ar"
                            name="name_ar"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item
                            label="Name En "
                            name="name_en"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Type..." />
                        </Form.Item>
                    </div>
                    {/* Item */}
                    <div className="col-lg-6">
                        <Form.Item label="Active " name="is_active">
                            <Switch
                                defaultChecked
                                onChange={(status) => handleSwitch(status, "is_active")}
                            />
                        </Form.Item>
                    </div>

                    <div className="col-lg-12">
                        <Form.Item label="Map Center" className="mb-0">

                        </Form.Item>


                        {isLoaded && (
                            <>
                                <Autocomplete
                                    onLoad={handleOnLoadAC}
                                    onPlaceChanged={handleOnACSelected}
                                >
                                    <input
                                        type="text"
                                        style={{
                                            boxSizing: `border-box`,
                                            border: `1px solid transparent`,
                                            width: `100%`,
                                            height: `50px`,
                                            padding: `0 12px`,
                                            marginBottom: `15px`,
                                            borderRadius: `3px`,
                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                            fontSize: `14px`,
                                            outline: `none`,
                                            textOverflow: `ellipses`
                                        }}
                                    />
                                </Autocomplete>
                                <p>{currentAddress}</p>
                                <GoogleMap
                                    onLoad={handleOnLoad}
                                    mapContainerStyle={{
                                        width: "100%",
                                        height: "400px"
                                    }}
                                    center={mapPosition}
                                    zoom={8}
                                    onCenterChanged={handleOnCenterChanged}
                                    onDragEnd={handleOnDragEnd}
                                >
                                    <MarkerF position={markerPosition} />
                                </GoogleMap>
                            </>
                        )}
                    </div>
                </div>
                <div className="row mt-5 pt-2">
                    <div className="col-lg-4 offset-lg-4">
                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size="large"
                                block
                                type="primary"
                                htmlType="submit"
                            >
                                {loading ? "Saving..." : "Save"}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddCity;
