import React, { useEffect, useState } from "react";
import {
    Input,
    Typography,
    Button,
    Spin,
    Form,
    Switch,
    notification,
} from "antd";
import { get, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditUser = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});

    useEffect(() => {
        get(`users/${id}`).then((res) => {
            setInputsValue(res.data.data);
            setFromLoading(true);
        });
    }, [id]);

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);
        put(`users/${id}`, {
            ...values
        })
            .then((res) => {
                notification.success({message:"Modified successfully"});
                setLoading(false);
                navigate("/users");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/users">
                    <ArrowLeftOutlined />
                </Link>
                Edit user
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        name: inputsValue.name,
                        email: inputsValue.email,
                        country_code: inputsValue.country_code,
                        phone_number: inputsValue.phone_number,
                        full_phone_number: inputsValue.full_phone_number,
                        is_active: inputsValue.is_active,
                        is_approved: inputsValue.is_approved,
                    }}
                    autoComplete="off"
                >
                    <div className="row">
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Email "
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input placeholder="Type..." type="email" />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Country Code "
                                name="country_code"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input readOnly />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Phone "
                                name="phone_number"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input readOnly type="number" />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item
                                label="Full Phone Number"
                                name="full_phone_number"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input readOnly/>
                            </Form.Item>
                        </div>
                        
                        <div className="col-lg-6">
                            <Form.Item label="Active " name="is_active">
                                <Switch defaultChecked={inputsValue.is_active} />
                            </Form.Item>
                        </div>
                        {/* Item */}
                        <div className="col-lg-6">
                            <Form.Item label="Approved " name="is_approved">
                                <Switch defaultChecked={inputsValue.is_approved} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Updating..." : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditUser;
