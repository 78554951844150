import React, { useEffect, useState } from "react";
import {
    Input,
    Typography,
    Button,
    Spin,
    Form,
    Select,
    notification,
} from "antd";
import { get, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditRole = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [fromLoading, setFromLoading] = useState(false);

    const [inputsValue, setInputsValue] = useState({});

    useEffect(() => {
        get(`roles/${id}`).then((res) => {
            setInputsValue(res.data);
            setFromLoading(true);
        });
    }, [id]);

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        put(`roles/${id}`, values)
            .then((res) => {
                notification.success({message:"Modified successfully"});
                setLoading(false);
                navigate("/roles");
            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    const [dataPermissions, setDataPermissions] = useState([]);
    const [optionPermissions, setOptionPermissions] = useState([]);

    useEffect(() => {
        setOptionPermissions(
            dataPermissions.map((d) => ({
                value: d.key,
                label: d.display_name,
            }))
        );
    }, [dataPermissions]);

    useEffect(() => {
        get("get-all-permissions").then((res) => {
            setDataPermissions(res.data);
        });
    }, []);

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                <Link className="text-dark me-3" to="/roles">
                    <ArrowLeftOutlined />
                    Edit Role
                </Link>
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={{
                        name: inputsValue.name,
                        permissions: inputsValue.permissions,
                    }}
                    autoComplete="off"
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="col-lg-12">
                            <Form.Item
                                label="Permissions"
                                name="permissions"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="w-100"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Select"
                                    options={optionPermissions}
                                />
                            </Form.Item>
                        </div>

                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Updating..." : "Update"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default EditRole;
