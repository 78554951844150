import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Input, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { post } from "../../api/axios";
import { PopupMenu } from "react-simple-widgets";

const { Header } = Layout;

const Dashboard = () => {

    const handleLogout = () => {
        post("auth/logout").then((res) => {
            localStorage.removeItem("tokenAdmin");
            localStorage.removeItem("loggedInAdmin");
            localStorage.removeItem("permissionsAdmin");
            localStorage.removeItem("authDataAdmin");
            window.location.href = "/login";
        })
        .catch((err) => {
            notification.error({
                message: "Error!",
                description: err.response.data.message,
            });
        });
    };

    const [userData, setUserData] = useState({
        'name': '',
    });
    useEffect(() => {
        const authDataAdmin = JSON.parse(localStorage.getItem("authDataAdmin"))
        if (authDataAdmin) {
            setUserData(JSON.parse(localStorage.getItem("authDataAdmin")));
        }
    }, []);

    return (
        <Header className="header-custom py-0 px-lg-3">
            <div className="container">
                <div className="row">
                    <div className="col-6 col-lg-5 d-none d-lg-block opacity-0">
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder="Search"
                            style={{ flex: "1", marginRight: 16 }}
                        />
                    </div>
                    <div className="col-12 col-lg-5 offset-lg-2 d-flex align-items-center justify-content-around justify-content-lg-end pe-lg-5">
                        <PopupMenu>
                            <button className="btn btn-link p-0" style={{ width:'60px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" id="user" x="0" y="0" enableBackground="new 0 0 16 16" version="1.1" viewBox="0 0 16 16" space="preserve">
                                    <path  id="path7" fill="#fff" stroke="none" d="M8 .986A7.022 7.022 0 0 0 .986 8c0 1.874.73 3.635 2.055 4.959A6.965 6.965 0 0 0 8 15.014 7.022 7.022 0 0 0 15.014 8 7.022 7.022 0 0 0 8 .986zm0 1A6.021 6.021 0 0 1 14.014 8a5.984 5.984 0 0 1-1.606 4.074 5.836 5.836 0 0 0-2.564-1.754 2.999 2.999 0 0 0 1.11-2.326A2.997 2.997 0 0 0 7.94 5.006a2.997 2.997 0 0 0-2.988 3.012c0 .929.436 1.75 1.104 2.298a5.846 5.846 0 0 0-2.526 1.698A5.964 5.964 0 0 1 1.986 8 6.021 6.021 0 0 1 8 1.986zm-.035 4.02c1.097 0 1.988.892 1.988 2.012A1.988 1.988 0 0 1 8.03 10c-.029 0-.057-.006-.086-.006-.025 0-.049.005-.074.006a1.994 1.994 0 0 1-1.916-2.006c0-1.096.892-1.988 2.012-1.988zm-.096 4.992c.024.001.048.008.072.008h.024c.022 0 .04-.007.062-.008a4.84 4.84 0 0 1 3.643 1.752A5.963 5.963 0 0 1 8 14.014a5.965 5.965 0 0 1-3.742-1.31 4.848 4.848 0 0 1 3.611-1.706z" />
                                </svg>
                            </button>

                            <div className="card card-user-profile text-start">
                                <div className="card-body px-4 py-4">

                                    <h5 className="text-center mb-0"> {userData.name} </h5>
                                    <p className="text-center mb-2 lh-2"> {userData.email} </p>


                                    <hr className="mb-0" style={{ margin: "0 -24px 0" }} />

                                    <div className="list-group list-group-flush">
                                        <Link to='/change-password' className="list-group-item list-group-item-action px-4 lh-1">
                                            <small>Change password</small>
                                        </Link>
                                    </div>

                                    <hr style={{ margin: "0 -24px 24px" }} />

                                    <div className="d-grid">
                                        <button onClick={handleLogout} className="btn btn-secondary">
                                            <small>Logout</small>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </PopupMenu>
                    </div>
                </div>
            </div>
        </Header>
    );
};

export default Dashboard;
