import LayoutComponent from "./components/layout/layout";
import { BrowserRouter } from "react-router-dom";
import { Space, Spin } from "antd";
import { useEffect, useState } from "react";
import "./App.scss";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div>
      <BrowserRouter>
        {loading ? (
          <div className="pre-loading">
            <Space size="middle">
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <></>
        )}
        <LayoutComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;
